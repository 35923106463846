<template>
  <div>
    <div
      :class="activeLead && activeLead !== '' ? 'col-sm-12 col-lg-offset-2 col-lg-8' : 'col-sm-offset-4 col-sm-4 col-md-offset-5 col-md-2'"
      class="col-xs-12"
    >
      <div
        class="panel panel-white"
        style="border: 2px dashed darkred !important;"
      >
        <div class="panel-heading clearfix">
          <h4 class="panel-title">
            Active Lead
          </h4>
        </div>
        <div class="panel-body">
          <div v-if="activeLead && activeLead !== ''">
            <div class="row">
              <div
                v-if="showSource"
                class="form-group col-xs-8 col-sm-6"
              >
                <label class="form-details-label">Source:</label>
                <p class="text-danger">
                  {{ prettyPrint(activeLead.source, 'Source') }}
                </p>
              </div>
              <div class="form-group col-xs-6">
                <label class="form-details-label">Product:</label>
                <p class="text-muted">
                  {{ prettyPrint(activeLead.product, 'Product') }}
                </p>
              </div>
            </div>
            <div class="row">
              <div
                v-if="activeLead.customerName && activeLead.customerName !== ''"
                class="form-group col-xs-8 col-sm-6"
              >
                <label class="form-details-label">Customer Name:</label>
                <p class="text-muted">
                  {{ activeLead.customerName }}
                </p>
              </div>
              <div
                v-else
                class="form-group col-xs-8 col-sm-6"
              >
                <label class="has-float-label">
                  <input
                    v-model="customerName"
                    placeholder=" "
                    type="text"
                  >
                  <span class="float-label">Customer Name:</span>
                </label>
              </div>
              <div class="form-group col-xs-4 col-sm-6">
                <label class="form-details-label">Mobile Number: </label>
                <p>
                  <a
                    :href="'tel:' + getCountryCode + activeLead.mobileNumber "
                    class="text-muted"
                  >{{ activeLead.mobileNumber }}</a>
                </p>
              </div>
            </div>
            <div class="row">
              <div
                v-if="activeLead.email && activeLead.email !== ''"
                class="form-group col-xs-8 col-sm-6"
              >
                <label class="form-details-label">Email:</label>
                <p class="text-muted">
                  {{ activeLead.email }}
                </p>
              </div>
              <div
                v-if="activeLead.pancard && activeLead.pancard !== ''"
                class="form-group col-xs-4 col-sm-6"
              >
                <label class="form-details-label">Pancard:</label>
                <p class="text-muted">
                  {{ activeLead.pancard }}
                </p>
              </div>
            </div>
            <div class="row">
              <div
                v-if="activeLead.address && activeLead.address !== ''"
                class="form-group col-xs-12 col-sm-6"
              >
                <label class="form-details-label">Address:</label>
                <p class="text-muted">
                  {{ activeLead.address }}
                </p>
              </div>
              <div
                v-if="activeLead.city && activeLead.city !== ''"
                class="form-group col-xs-12 col-sm-6"
              >
                <label class="form-details-label">City:</label>
                <p class="text-muted">
                  {{ activeLead.city }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-xs-12 col-sm-6">
                <label class="has-float-label">
                  <VueMultiselect
                    v-model="activeLead.feedback"
                    :allow-empty="false"
                    :class="{ 'placeholder-shown': (!activeLead.feedback || activeLead.feedback === '') }"
                    :multiple="false"
                    :options="getFeedbackOptions"
                    :placeholder="null"
                    :searchable="false"
                    :show-labels="false"
                    label="name"
                    track-by="id"
                  />
                  <span class="float-label">Feedback</span>
                </label>
              </div>
              <div
                v-if="allowRemarks"
                class="form-group col-xs-12 col-sm-6"
              >
                <label class="has-float-label">
                  <input
                    v-model="activeLead.remarks"
                    placeholder=" "
                    type="text"
                  >
                  <span class="float-label">Remarks</span>
                </label>
              </div>
            </div>
            <div
              v-if="isCallbackTimeRequired"
              class="row"
            >
              <div class="col-xs-12 col-sm-6">
                <label class="has-float-label">
                  <VueMultiselect
                    v-model="activeLeadCallbackPreset"
                    :allow-empty="false"
                    :class="{ 'placeholder-shown': (!activeLeadCallbackPreset || activeLeadCallbackPreset === '') }"
                    :multiple="false"
                    :options="_getCallbackPresets"
                    :placeholder="null"
                    :searchable="false"
                    :show-labels="false"
                    label="name"
                    track-by="id"
                  />
                  <span class="float-label">Call Back Time</span>
                </label>
              </div>
              <date-range-picker
                v-if="activeLeadCallbackPreset && activeLeadCallbackPreset.id === 'Custom'"
                ref="picker"
                v-model="activeLeadCallback"
                :locale-data="getLocaleData"
                :min-date="getDateToday"
                :opens="'right'"
                :ranges="false"
                :single-date-picker="true"
                :time-picker="true"
                :time-picker-increment="1"
                :time-picker24hour="false"
                class="col-xs-12 col-sm-6 m-t-sm"
              >
                <div
                  slot="input"
                  slot-scope="picker"
                  style="min-width: 250px;"
                >
                  {{ picker.startDate | date }}
                </div>
              </date-range-picker>
            </div>
            <div
              v-show="allowOtherProducts"
              class="row"
            >
              <div class="form-group col-xs-12 col-sm-6">
                <label class="has-float-label">
                  <VueMultiselect
                    v-model="activeLead.interestedProducts"
                    :allow-empty="true"
                    :class="{ 'placeholder-shown': (!activeLead.interestedProducts || activeLead.interestedProducts === '') }"
                    :multiple="true"
                    :options="_getOtherProducts"
                    :placeholder="null"
                    :searchable="false"
                    :show-labels="false"
                    label="name"
                    track-by="id"
                  />
                  <span class="float-label">Interested In Other</span>
                </label>
              </div>
            </div>
          </div>
          <div
            v-if="activeLead && activeLead !== ''"
            class="row"
          >
            <div class="form-group m-t-sm col-xs-12 col-sm-3">
              <button
                id="updateActiveLead"
                class="btn btn-block btn-danger"
                type="button"
                @click.stop.prevent="updateActiveLead"
              >
                Submit Feedback
              </button>
            </div>
            <div
              v-if="activeLead && activeLead !== ''"
              class="form-group m-t-sm col-xs-12 col-sm-3"
            >
              <button
                id="createForm"
                class="btn btn-block btn-success"
                type="button"
                @click.stop.prevent="createForm"
              >
                {{ 'Create ' + prettyPrint(activeLead.product, 'Product') + ' Form' }}
              </button>
            </div>
          </div>
          <div
            v-show="!activeLead || activeLead === ''"
            class="row"
          >
            <div class="col-xs-12">
              <button
                id="newActiveLead"
                class="btn btn-block btn-info"
                type="button"
                @click.stop.prevent="fetchNewActiveLead"
              >
                Get New Lead
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {API, Auth, graphqlOperation} from "aws-amplify";
import * as mutations from "../graphql/mutations";
import {leadFeedbackCategories} from "@/constants";
import VueMultiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";

export default {
  name: "ActiveLead",
  components: {
    VueMultiselect,
    DateRangePicker
  },
  filters: {
    date: function (date) {
      return moment(date).format('dddd, MMMM Do hh:mm A');
    }
  },
  data() {
    return {
      activeLead: null,
      customerName: null,
      activeLeadCallback: {
        startDate: moment().subtract(1, 'hours').startOf('hour')
      },
      activeLeadCallbackPreset: null
    };
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getEnabledSources",
      "getLeads",
      "getAllLeads",
      "getUsers",
      "getLeadFeedbacks",
      "getProducts",
      "getSettings"
    ]),
    getFeedbackOptions: function () {
      return _.sortBy(_.filter(this.getLeadFeedbacks, (feedback) => {
        return feedback.category !== leadFeedbackCategories.CLOSED && _.includes(feedback.products, this.activeLead.product);
      }), 'name');
    },
    isCallbackTimeRequired: function () {
      return this.activeLead && this.activeLead.feedback && _.includes([leadFeedbackCategories.INTERESTED, leadFeedbackCategories.CALL_LATER], this.activeLead.feedback.category);
    },
    getLocaleData() {
      return {
        format: 'dddd, MMMM Do hh:mm A',
        firstDay: moment.localeData().firstDayOfWeek()
      }
    },
    getDateToday() {
      return moment().format('LL');
    },
    _getCallbackPresets() {
      return [
        {id: 'HalfHour', name: 'In 30 Minutes'},
        {id: '1Hour', name: 'In 1 Hour'},
        {id: '2Hours', name: 'In 2 Hours'},
        {id: '6Hours', name: 'In 6 Hours'},
        {id: '12Hours', name: 'In 12 Hours'},
        {id: '24Hours', name: 'In 24 Hours'},
        {id: 'Evening', name: 'In the Evening'},
        {id: 'Morning', name: 'Tomorrow Morning'},
        {id: 'Custom', name: 'Custom'}
      ];
    },
    allowRemarks() {
      return this.activeLead && this.activeLead.feedback && _.includes([leadFeedbackCategories.INTERESTED, leadFeedbackCategories.CALL_LATER], this.activeLead.feedback.category);
    },
    _getOtherProducts() {
      return _.sortBy(_.filter(this.getProducts, (product) => {
        return !this.activeLead || (product.id !== this.activeLead.product);
      }), 'name');
    },
    allowOtherProducts() {
      return this.activeLead && this.activeLead.feedback && this.activeLead.feedback.category !== leadFeedbackCategories.NO_CONTACT;
    },
    showSource() {
      const showSourceSetting = _.find(this.getSettings, ['name', 'showLeadSource']);
      return showSourceSetting && showSourceSetting.value ? showSourceSetting.value === 'true' : true;
    },
    getCountryCode() {
      const countryCodeSetting = _.find(this.getSettings, ['name', 'countryCode']);
      if (countryCodeSetting && countryCodeSetting.value) {
        return countryCodeSetting.value;
      } else {
        return '';
      }

    }
  },
  watch: {
    activeLeadCallbackPreset(newValue) {
      if (!newValue || !newValue.id || newValue.id === '') {
        return;
      }
      if (newValue.id === 'HalfHour') {
        this.activeLeadCallback = {
          startDate: moment().add(30, 'minutes')
        };
      } else if (newValue.id === '1Hour') {
        this.activeLeadCallback = {
          startDate: moment().add(1, 'hours')
        };
      } else if (newValue.id === '2Hours') {
        this.activeLeadCallback = {
          startDate: moment().add(2, 'hours')
        };
      } else if (newValue.id === '6Hours') {
        this.activeLeadCallback = {
          startDate: moment().add(6, 'hours')
        };
      } else if (newValue.id === '12Hours') {
        this.activeLeadCallback = {
          startDate: moment().add(12, 'hours')
        };
      } else if (newValue.id === '24Hours') {
        this.activeLeadCallback = {
          startDate: moment().add(24, 'hours')
        };
      } else if (newValue.id === 'Evening') {
        this.activeLeadCallback = {
          startDate: moment().startOf('day').add(18, 'hours')
        };
      } else if (newValue.id === 'Morning') {
        this.activeLeadCallback = {
          startDate: moment().add(1, 'days').startOf('day').add(9, 'hours')
        };
      }
    }
  },
  mounted() {
    this.activeLead = _.find(this.getLeads, (lead) => {
      return !lead.feedback || lead.feedback === '';
    });
  },
  methods: {
    ...mapMutations([
      "UPDATE_LEADS",
      "UPDATE_ALL_LEADS"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    prettyPrint: function (value, type) {
      if (type === 'Source') {
        let source = _.find(this.getEnabledSources, (source) => {
          return source.id === value;
        });
        if (source) {
          return source.name;
        } else {
          return 'Source disabled or removed';
        }
      } else if (type === 'Product') {
        let productName = _.result(_.find(this.getProducts, (product) => {
          return product.id === value;
        }), 'name');
        if (productName) {
          return productName;
        } else {
          return value;
        }
      }
    },
    async fetchNewActiveLead() {
      let newLeadButton = $('#newActiveLead');
      newLeadButton.attr('disabled', true);
      let activeLead = _.find(this.getLeads, (lead) => {
        return !lead.feedback || lead.feedback === '';
      });
      if (!activeLead || activeLead === '') {
        try {
          let {
            leads
          } = await API.get("SalesVoltPrivate", '/leads', {
            headers: {
              Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            queryStringParameters: {
              'createLog': false
            }
          });
          if (leads.length < 1) {
            this.setMessage('v-notify-error', 'Sorry!', 'No leads available at this time. Contact your supervisor!');
            newLeadButton.attr('disabled', false);
          } else {
            this.UPDATE_LEADS(_.uniqBy(_.concat(this.getLeads, leads), 'id'));
            this.activeLead = _.cloneDeep(leads[0]);
            this.setMessage('v-notify-info', 'Get Busy!', "New lead(s) fetched.");
            newLeadButton.attr('disabled', false);
            const currentTimeEpoch = moment().format('X');
            _.forEach(leads, (lead) => {
              this.createLog(lead.id, "ASSIGNED", currentTimeEpoch);
            })
          }
        } catch (err) {
          this.setMessage('v-notify-error', 'Sorry!', 'No leads available at this time. Contact your supervisor!');
          newLeadButton.attr('disabled', false);
        }
      } else {
        this.activeLead = _.cloneDeep(activeLead);
        newLeadButton.attr('disabled', false);
      }
    },
    async updateActiveLead() {
      let updateButton = $('#updateActiveLead');
      updateButton.attr('disabled', true);
      const currentTimeEpoch = moment().format('X');
      if (!this.activeLead.feedback || this.activeLead.feedback === '') {
        this.setMessage('v-notify-error', 'Oops!', "Lead feedback must be entered!");
        updateButton.attr('disabled', false);
        return;
      }
      if (this.isCallbackTimeRequired &&
          (!this.activeLeadCallback
              || !this.activeLeadCallback.startDate
              || (_.parseInt(moment(this.activeLeadCallback.startDate, 'dddd, MMMM Do hh:mm A').format('X')) <= currentTimeEpoch))) {
        this.setMessage('v-notify-error', 'Sorry!', "Must select a valid call back later time!");
        updateButton.attr('disabled', false);
        return;
      }
      try {
        if (!this.activeLead.customerName || this.activeLead.customerName === '') {
          this.activeLead.customerName = this.customerName;
        }
        if (this.activeLead.customerName === '') {
          this.activeLead.customerName = null;
        }
        let newLead = {
          id: this.activeLead.id,
          customerName: this.activeLead.customerName,
          source: this.activeLead.source,
          product: this.activeLead.product,
          feedback: this.activeLead.feedback.id,
          updatedAtEpoch: currentTimeEpoch,
          callbackEpoch: this.isCallbackTimeRequired ? _.parseInt(moment(this.activeLeadCallback.startDate, 'dddd, MMMM Do hh:mm A').format('X')) : null,
          isCallLater: this.activeLead.feedback.category === leadFeedbackCategories.CALL_LATER ? 'YES' : 'NO',
          isClosed: this.activeLead.feedback.category === leadFeedbackCategories.CLOSED ? 'YES' : 'NO',
          isInterested: this.activeLead.feedback.category === leadFeedbackCategories.INTERESTED ? 'YES' : 'NO',
          isNoContact: this.activeLead.feedback.category === leadFeedbackCategories.NO_CONTACT ? 'YES' : 'NO',
          isReusable: this.activeLead.feedback.category === leadFeedbackCategories.REUSABLE ? 'YES' : 'NO',
          isUnusable: this.activeLead.feedback.category === leadFeedbackCategories.UNUSABLE ? 'YES' : 'NO',
          remarks: this.allowRemarks && this.activeLead.remarks && this.activeLead.remarks !== '' ? this.activeLead.remarks : null,
          expectedVersion: this.activeLead.version
        };
        if (newLead.isInterested === 'NO' && newLead.isCallLater === 'NO') {
          newLead.assignedTo = null;
          newLead.isAssigned = 'NA';
        }
        if (newLead.isNoContact === 'YES') {
          if (!this.activeLead.noContactAttempt || this.activeLead.noContactAttempt < 1) {
            newLead.noContactAttempt = 1;
          } else {
            newLead.noContactAttempt = _.parseInt(this.activeLead.noContactAttempt) + 1;
          }
          newLead.interestedProducts = null;
          newLead.isInterestedOther = null;
        } else {
          newLead.noContactAttempt = null;
          if (this.activeLead.interestedProducts && this.activeLead.interestedProducts.length > 0) {
            newLead.interestedProducts = _.map(this.activeLead.interestedProducts, 'id');
            newLead.isInterestedOther = 'YES';
            newLead.referralOwner = this.getLoggedInUser.username;
          } else {
            newLead.interestedProducts = null;
            newLead.isInterestedOther = null;
            newLead.referralOwner = null;
          }
        }
        let oldList = _.cloneDeep(this.getLeads);
        let leadIdx = _.findIndex(oldList, (oldLead) => {
          return oldLead.id === this.activeLead.id;
        });
        if (leadIdx !== -1) {
          _.remove(oldList, (lead) => {
            return lead.id === this.activeLead.id;
          });
          this.UPDATE_LEADS(oldList);
        }
        const {
          data: {updateLead}
        } = await API.graphql(graphqlOperation(mutations.updateLead, {
          input: newLead
        }));
        this.createLog(updateLead.id, updateLead.feedback);

        let oldLeads = _.cloneDeep(this.getLeads);
        let leadIndex = _.findIndex(oldLeads, (oldLead) => {
          return oldLead.id === updateLead.id;
        });
        if (leadIndex !== -1) {
          if (newLead.isInterested === 'YES' || newLead.isCallLater === 'YES') {
            oldLeads[leadIndex] = updateLead;
            this.UPDATE_LEADS(oldLeads);
          } else {
            _.remove(oldLeads, (lead) => {
              return lead.id === updateLead.id;
            });
            this.UPDATE_LEADS(oldLeads);
          }
        } else {
          if (newLead.isInterested === 'YES' || newLead.isCallLater === 'YES') {
            oldLeads.push(updateLead);
            this.UPDATE_LEADS(oldLeads);
          }
        }
        this.activeLead = null;
        this.activeLeadCallback = {
          startDate: moment().subtract(1, 'hours').startOf('hour')
        };
        this.activeLeadCallbackPreset = null;
        this.customerName = null;
        this.fetchNewActiveLead();
        updateButton.attr('disabled', false);
        this.setMessage('v-notify-success', 'Nice!', "Lead feedback submitted.");
      } catch (err) {
        updateButton.attr('disabled', false);
        this.setMessage('v-notify-error', 'Sorry!', err);
      }
    },
    createForm: function () {
      let updateButton = $('#updateActiveLead');
      let createFormButton = $('#createForm');
      updateButton.attr('disabled', true);
      createFormButton.attr('disabled', true);
      this.setMessage('confirm', 'Are you sure?', 'You are about to create a new form for this lead!').then((data) => {
        if (data && data.data) {
          if (_.includes(['c03143c4-bcfb-4526-a86f-ca29fc402b78', '597c5c1a-bc21-47c9-a69d-69a4c68f73ab', '2a32cec2-1d11-48a6-84aa-9a8c9fd33c12'], this.activeLead.product)) {
            this.createNewForm();
          } else {
            this.temporaryLeadClosedUpdate();
          }
        } else {
          updateButton.attr('disabled', false);
          createFormButton.attr('disabled', false);
        }
      });
    },
    async temporaryLeadClosedUpdate() {
      let updateButton = $('#updateActiveLead');
      let createFormButton = $('#createForm');
      const currentTimeEpoch = moment().format('X');
      try {
        let newLead = {
          id: this.activeLead.id,
          callbackEpoch: null,
          isAssigned: 'NA',
          assignedTo: null,
          isClosed: 'YES',
          isNoContact: 'NO',
          isInterested: 'NO',
          isReusable: 'NO',
          isUnusable: 'NO',
          isCallLater: 'NO',
          noContactAttempt: null,
          updatedAtEpoch: currentTimeEpoch,
          expectedVersion: this.activeLead.version
        };
        let closedFeedback = _.find(this.getLeadFeedbacks, (feedback) => {
          return feedback.category === leadFeedbackCategories.CLOSED && _.includes(feedback.products, this.activeLead.product);
        });
        if (closedFeedback && closedFeedback !== '') {
          newLead.feedback = closedFeedback.id;
          let oldList = _.cloneDeep(this.getLeads);
          let leadIdx = _.findIndex(oldList, (oldLead) => {
            return oldLead.id === this.activeLead.id;
          });
          if (leadIdx !== -1) {
            _.remove(oldList, (lead) => {
              return lead.id === this.activeLead.id;
            });
            this.UPDATE_LEADS(oldList);
          }

          const {
            data: {updateLead}
          } = await API.graphql(graphqlOperation(mutations.updateLead, {
            input: newLead
          }));
          this.createLog(updateLead.id, updateLead.feedback);
          this.activeLead = null;
          this.activeLeadCallback = {
            startDate: moment().subtract(1, 'hours').startOf('hour')
          };
          this.activeLeadCallbackPreset = null;
          this.customerName = null;
          this.fetchNewActiveLead();
          updateButton.attr('disabled', false);
          createFormButton.attr('disabled', false);
          this.setMessage('v-notify-success', 'Nice!', "Lead Closed!");
          $("#leadModal").modal('hide');
        } else {
          updateButton.attr('disabled', false);
          createFormButton.attr('disabled', false);
          this.setMessage('v-notify-error', 'Sorry!', 'Unable to close lead at this time. Try again later!');
        }
      } catch (err) {
        updateButton.attr('disabled', false);
        createFormButton.attr('disabled', false);
        this.setMessage('v-notify-error', 'Sorry!', err);
      }
    },
    async createNewForm() {
      try {
        let createLeadInfo = _.cloneDeep(this.activeLead);
        this.activeLead = null;
        this.activeLeadCallback = {
          startDate: moment().subtract(1, 'hours').startOf('hour')
        };
        this.activeLeadCallbackPreset = null;
        this.setMessage('v-notify-info', 'Please Wait!', "You will now be re-directed to the form entry page.");
        $('body').removeClass('loaded');
        this.$router.push({
          name: 'newForm',
          params: {
            productId: createLeadInfo.product,
            leadId: createLeadInfo.id,
            mobileNumber: createLeadInfo.mobileNumber,
            expectedVersion: createLeadInfo.version
          }
        }, () => {
          $('body').addClass('loaded');
        }, (err) => {
          $('body').addClass('loaded');
          console.error(err);
        });
      } catch (err) {
        this.setMessage('v-notify-error', 'Sorry!', err);
      }
    },
    async createLog(leadId, action, currentTimeEpoch = null) {
      if (!currentTimeEpoch) {
        currentTimeEpoch = moment().format('X');
      }
      let retentionDays = moment(currentTimeEpoch, 'X').add(30, 'days');
      const retentionSetting = _.find(this.getSettings, ['name', 'leadHistoryRetention']);
      if (retentionSetting && retentionSetting.value) {
        try {
          retentionDays = moment(currentTimeEpoch, 'X').add(_.parseInt(retentionSetting.value), 'days');
        } catch (e) {
          retentionDays = moment(currentTimeEpoch, 'X').add(30, 'days');
        }
      }
      try {
        let supervisor = _.find(this.getUsers, (user) => {
          return user.id === this.getLoggedInUser.supervisor;
        });
        let manager = {};
        if (supervisor) {
          manager = _.find(this.getUsers, (user) => {
            return user.id === supervisor.supervisor;
          });
        }
        let {
          data: {createLog}
        } = await API.graphql(graphqlOperation(mutations.createLog, {
          input: {
            logLeadId: leadId,
            action: "LEAD",
            actionType: action,
            supervisor: _.result(supervisor, 'username'),
            branchManager: _.result(manager, 'username'),
            actionEpoch: currentTimeEpoch,
            expirationEpoch: _.parseInt(retentionDays.format('X'))
          }
        }));

        let oldLeads = _.cloneDeep(this.getAllLeads);
        oldLeads.push(createLog);
        this.UPDATE_ALL_LEADS(_.uniqBy(oldLeads, 'id'));
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style scoped>

</style>
