<template>
  <div class="table-responsive">
    <table
      id="submittedFormsList"
      class="table table-striped table-bordered"
      style="width:100%"
    >
      <thead>
        <tr>
          <th class="appId">
            Application #
          </th>
          <th class="createdAt includeInExport">
            Submission Date
          </th>
          <th
            :class="{'includeInExport' : jobType === 'Owner'}"
            class="createdAtTime"
          >
            Submission Time
          </th>
          <th class="bankVerificationDate includeInExport">
            Bank Verification Date
          </th>
          <th class="city includeInExport">
            Resident City
          </th>
          <th class="customerName includeInExport">
            Customer Name
          </th>
          <th class="dateOfBirth">
            Date Of Birth
          </th>
          <th class="company">
            Company Name
          </th>
          <th class="employmentStatus">
            Employment Status
          </th>
          <th class="owner includeInExport">
            Owner
          </th>
          <th class="supervisor includeInExport">
            Supervisor
          </th>
          <th class="branchManager includeInExport">
            Branch Manager
          </th>
          <th class="status includeInExport">
            Status
          </th>
          <th class="statusJustifications includeInExport">
            Status Justifications
          </th>
          <th class="verifiedIncomeProgram includeInExport">
            Verified Income Program
          </th>
          <th class="referenceNumber includeInExport">
            Reference Number
          </th>
          <th class="remarks includeInExport">
            Remarks
          </th>
          <th class="offerType includeInExport">
            Offer Type
          </th>
          <th class="product includeInExport">
            Product
          </th>
          <th class="firstName">
            First Name
          </th>
          <th class="lastName">
            Last Name
          </th>
          <th class="pancard includeInExport">
            Pancard
          </th>
          <th
            :class="{'includeInExport' : jobType === 'Owner'}"
            class="email"
          >
            Email
          </th>
          <th
            :class="{'includeInExport' : jobType === 'Owner'}"
            class="mobileNumber"
          >
            Mobile Number
          </th>
          <th
            :class="{'includeInExport' : jobType === 'Owner'}"
            class="updatedBy"
          >
            Last Updated By
          </th>
          <th
            :class="{'includeInExport' : jobType === 'Owner'}"
            class="updatedAt"
          >
            Updated Date
          </th>
          <th
            :class="{'includeInExport' : jobType === 'Owner'}"
            class="updatedAtTime"
          >
            Updated Time
          </th>
        </tr>
      </thead>
    </table>
    <AIPFormModal
      :form-details="formDetails"
      :job-type="jobType"
      :report-only="reportOnly"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import AIPFormModal from "../../../components/AIPFormModal";

export default {
  name: "DashboardForms",
  components: {
    AIPFormModal
  },
  beforeRouteLeave(to, from, next) {
    let formModal = $("#formDetailsModal");
    if ((formModal.data('bs.modal') || {}).isShown) {
      formModal.modal('hide');
      document.getElementById('submittedFormsList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else {
      next();
    }
  },
  data() {
    return {
      jobType: '',
      formsTable: null,
      tableData: [],
      formDetails: {}
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getStatuses",
      "getTitles",
      "getProducts",
      "getUsers",
      "getForms"
    ]),
    ...mapState([
      'formFilterTerm',
      'forms',
      'updateSubscriptionForm',
      'deleteSubscriptionForm',
      'loggedInUser',
      'activeDashboardBranchId'
    ]),
    reportOnly: function () {
      let formStatus = _.find(this.getStatuses, (status) => {
        return status.id === this.formDetails.status;
      });
      let reportOnlyStatus = true;
      if (formStatus) {
        reportOnlyStatus = !_.includes(formStatus.alltime, this.jobType) && !_.includes(formStatus.daily, this.jobType);
      }
      return _.includes(['User', 'Supervisor', 'Manager', 'Partner', 'Owner'], this.jobType) || reportOnlyStatus;
    }
  },
  watch: {
    formFilterTerm(newValue) {
      if (newValue === this.formsTable.search()) {
        return;
      }
      if (newValue && newValue !== '') {
        this.formsTable.search(newValue).draw();
      } else {
        this.formsTable.search('').draw();
      }
    },
    forms(newValue) {
      let newForms = _.differenceBy(newValue, this.tableData, 'id');
      if (newForms.length > 0) {
        this.tableData = _.concat(this.tableData, newForms);
        this.formsTable.rows
            .add(newForms)
            .draw();
      }
    },
    updateSubscriptionForm(newValue) {
      let oldForms = _.cloneDeep(this.tableData);
      let formIndex = _.findIndex(oldForms, (oldForm) => {
        return oldForm.id === newValue.id;
      });
      if (formIndex !== -1) {
        oldForms[formIndex] = newValue;
        this.tableData = oldForms;
        this.formsTable.row("#" + newValue.id)
            .invalidate()
            .data(newValue)
            .draw();
      }
    },
    deleteSubscriptionForm(newValue) {
      let oldForms = _.cloneDeep(this.tableData);
      _.remove(oldForms, (oldForm) => {
        return oldForm.id === newValue.id;
      });
      this.tableData = oldForms;
      this.formsTable.row("#" + newValue.id)
          .remove()
          .draw();
    },
    loggedInUser(newValue) {
      if (newValue && newValue !== '') {
        if (newValue.jobTitle && newValue.jobTitle !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === newValue.jobTitle;
          });
          if (jobTitle) {
            this.jobType = jobTitle.access;
          }
        }
      }
    },
    activeDashboardBranchId(newValue) {
      if (newValue && newValue !== '') {
        this.tableData = [];
        this.formsTable.clear().draw();
      }
    }
  },
  mounted() {
    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          this.jobType = jobTitle.access;
        }
      }
    }

    this.tableData = this.getForms;
    this.createDatatable();
  },
  methods: {
    ...mapMutations([
      "UPDATE_FORM_FILTER_TERM"
    ]),
    createDatatable: function () {
      let sortBy = [[25, "desc"], [26, "desc"]];
      let hiddenColumns = [
        "bankVerificationDate",
        "branchManager",
        "createdAt",
        'createdAtTime',
        "city",
        "company",
        "dateOfBirth",
        "email",
        "employmentStatus",
        "firstName",
        "lastName",
        "mobileNumber",
        "offerType",
        "owner",
        "pancard",
        "referenceNumber",
        "supervisor",
        "updatedBy",
        "verifiedIncomeProgram"
      ];
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        }
      ];
      if (this.jobType === 'BackOffice') {
        buttons.push(
            {
              extend: 'excelHtml5',
              autoFilter: true,
              text: '<i class="far fa-file-excel"></i>',
              titleAttr: 'Excel',
              exportOptions: {
                columns: '.includeInExport'
              }
            }
        );
        hiddenColumns = [
          "appId",
          "bankVerificationDate",
          "branchManager",
          "company",
          "dateOfBirth",
          "email",
          "employmentStatus",
          "firstName",
          "lastName",
          "mobileNumber",
          "offerType",
          "pancard",
          "referenceNumber",
          "remarks",
          "supervisor",
          "updatedBy",
          "updatedAt",
          "updatedAtTime",
          "verifiedIncomeProgram"
        ];
        sortBy = [[1, "desc"], [2, "desc"]];
      } else if (this.jobType === 'Documents') {
        hiddenColumns = [
          "appId",
          "bankVerificationDate",
          "branchManager",
          "createdAt",
          'createdAtTime',
          "city",
          "company",
          "dateOfBirth",
          "email",
          "employmentStatus",
          "firstName",
          "lastName",
          "offerType",
          "owner",
          "pancard",
          "remarks",
          "statusJustifications",
          "supervisor",
          "updatedBy",
          "verifiedIncomeProgram"
        ];
      } else if (this.jobType === 'OutCaller') {
        hiddenColumns = [
          "bankVerificationDate",
          "branchManager",
          "createdAt",
          'createdAtTime',
          "city",
          "company",
          "dateOfBirth",
          "email",
          "employmentStatus",
          "firstName",
          "lastName",
          "offerType",
          "owner",
          "pancard",
          "referenceNumber",
          "remarks",
          "supervisor",
          "verifiedIncomeProgram"
        ];
      } else if (this.jobType === 'FormAccept') {
        hiddenColumns = [
          "bankVerificationDate",
          "branchManager",
          "createdAt",
          'createdAtTime',
          "city",
          "company",
          "dateOfBirth",
          "email",
          "employmentStatus",
          "firstName",
          "lastName",
          "mobileNumber",
          "offerType",
          "owner",
          "pancard",
          "referenceNumber",
          "remarks",
          "supervisor",
          "verifiedIncomeProgram"
        ];
      } else if (this.jobType === 'MIS') {
        buttons.push(
            {
              extend: 'excelHtml5',
              text: '<i class="far fa-file-excel"></i>',
              titleAttr: 'Excel',
              exportOptions: {
                columns: [0, 5, 4, 6, 7, 8, 17, 25, 12]
              }
            }
        );
      } else if (this.jobType === 'User') {
        hiddenColumns = [
          "appId",
          "bankVerificationDate",
          "branchManager",
          "city",
          "company",
          "dateOfBirth",
          "email",
          "employmentStatus",
          "firstName",
          "lastName",
          "mobileNumber",
          "offerType",
          "owner",
          "pancard",
          "referenceNumber",
          "remarks",
          "statusJustifications",
          "supervisor",
          "verifiedIncomeProgram"
        ];
      } else if (this.jobType === 'Supervisor') {
        hiddenColumns = [
          "appId",
          "bankVerificationDate",
          "branchManager",
          "company",
          "dateOfBirth",
          "email",
          "employmentStatus",
          "firstName",
          "lastName",
          "mobileNumber",
          "offerType",
          "pancard",
          "referenceNumber",
          "remarks",
          "statusJustifications",
          "supervisor",
          "verifiedIncomeProgram"
        ];
        sortBy = [[1, "desc"], [2, "desc"]];
      } else if (this.jobType === 'Manager') {
        hiddenColumns = [
          "appId",
          "bankVerificationDate",
          "branchManager",
          "company",
          "dateOfBirth",
          "email",
          "employmentStatus",
          "firstName",
          "lastName",
          "mobileNumber",
          "offerType",
          "pancard",
          "referenceNumber",
          "remarks",
          "statusJustifications",
          "verifiedIncomeProgram"
        ];
        sortBy = [[1, "desc"], [2, "desc"]];
      } else if (this.jobType === 'Owner' || this.jobType === 'Partner') {
        buttons.push(
            {
              extend: 'excelHtml5',
              autoFilter: true,
              text: '<i class="far fa-file-excel"></i>',
              titleAttr: 'Excel',
              exportOptions: {
                columns: '.includeInExport'
              }
            }
        );
        hiddenColumns = [
          "appId",
          "bankVerificationDate",
          "company",
          "dateOfBirth",
          "email",
          "employmentStatus",
          "firstName",
          "lastName",
          "mobileNumber",
          "offerType",
          "pancard",
          "referenceNumber",
          "remarks",
          "statusJustifications",
          "verifiedIncomeProgram"
        ];
        sortBy = [[1, "desc"], [2, "desc"]];
      }
      if (!_.includes(['Supervisor', 'Manager', 'Partner', 'Owner'])) {
        $('#submittedFormsList thead tr').clone(true).appendTo('#submittedFormsList thead');
        $('#submittedFormsList thead tr:eq(0) th').each((i, element) => {
          $(element).html('<label class="has-float-label" style="margin-bottom: 0 !important;"><input type="text" placeholder=" " /><span class="float-label">Search</span></label>');

          $('input', element).on('keyup change', (e) => {
            e.preventDefault();
            let target = e.currentTarget;
            if (this.formsTable.column(i).search() !== target.value) {
              this.formsTable
                  .column(i)
                  .search(target.value)
                  .draw();
            }
          });
        });
      }
      this.formsTable = $('#submittedFormsList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.tableData,
        rowId: 'id',
        columns: [
          {
            data: 'appId',
            defaultContent: ""
          },
          {
            data: 'createdAt'
          },
          {
            data: 'createdAt'
          },
          {
            data: 'bankVerificationDate',
            defaultContent: ""
          },
          {
            data: 'city',
            defaultContent: ""
          },
          {
            data: 'firstName',
            defaultContent: ""
          },
          {
            data: 'dateOfBirth',
            defaultContent: ""
          },
          {
            data: 'company',
            defaultContent: ""
          },
          {
            data: 'employmentStatus',
            defaultContent: ""
          },
          {
            data: 'owner',
            defaultContent: ""
          },
          {
            data: 'supervisor',
            defaultContent: ""
          },
          {
            data: 'branchManager',
            defaultContent: ""
          },
          {
            data: 'status',
            defaultContent: ""
          },
          {
            data: 'statusJustifications',
            defaultContent: ""
          },
          {
            data: 'verifiedIncomeProgram',
            defaultContent: ""
          },
          {
            data: 'referenceNumber',
            defaultContent: ""
          },
          {
            data: 'remarks',
            defaultContent: ""
          },
          {
            data: 'offerType',
            defaultContent: ""
          },
          {
            data: 'product',
            defaultContent: ""
          },
          {
            data: 'firstName'
          },
          {
            data: 'lastName'
          },
          {
            data: 'pancard'
          },
          {
            data: 'email',
            defaultContent: ""
          },
          {
            data: 'mobileNumber',
            defaultContent: ""
          },
          {
            data: 'updatedBy',
            defaultContent: ""
          },
          {
            data: 'updatedAt',
            defaultContent: ""
          },
          {
            data: 'updatedAt',
            defaultContent: ""
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('DD-MM-YYYY');
              } else if (!data || data === '') {
                return '';
              } else {
                return moment(data, "YYYY-MM-DD").format('DD-MM-YYYY');
              }
            },
            targets: ['createdAt', 'bankVerificationDate', 'dateOfBirth', 'updatedAt']
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('h:mm A');
              } else {
                return '';
              }
            },
            targets: ["createdAtTime", "updatedAtTime"]
          },
          {
            render: (data, type, row) => {
              return data + " " + row.lastName;
            },
            targets: "customerName"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: ["owner", "supervisor", "branchManager", "updatedBy"]
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Status');
            },
            targets: "status"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Array');
            },
            targets: "statusJustifications"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Product');
            },
            targets: "product"
          },
          {
            visible: false,
            targets: hiddenColumns
          }
        ],
        "fnRowCallback": (nRow, aData) => {
          $(nRow).removeClass('highlight-row');
          $(nRow).removeClass('vip-row');
          if (aData.status === '1fdca13d-adf6-411a-a002-a33e228d22df' && this.jobType === 'BackOffice') {  //NOTE: Hardcoded id = bad idea :(
            $(nRow).addClass('highlight-row');
          } else if (aData.status === 'a9525cb3-d038-4eae-8236-27e2e056597b' && this.jobType === 'Documents') {
            $(nRow).addClass('highlight-row');
          } else if (aData.status === 'b9d6b3a3-5a14-445d-b71e-bcfb64382bb9' && this.jobType === 'CentralBackOffice') {
            $(nRow).addClass('highlight-row');
          } else if (aData.status === 'd168ee85-8715-4529-acdd-5263d31a3777' && this.jobType === 'OutCaller') {
            $(nRow).addClass('highlight-row');
          } else if (aData.status === '06eed24f-1743-4c9c-9b9e-8525b97b8da5' && this.jobType === 'FormAccept') {
            $(nRow).addClass('highlight-row');
          } else if (aData.status === 'ce51aa38-a453-4ae2-b8c4-137b9ec759ff' && this.jobType === 'CentralBackOffice') {
            $(nRow).addClass('highlight-row');
          } else if (aData.status === 'c92f7975-9fc0-4ee3-95a3-e80b5f3a6b5d' && this.jobType === 'MIS') {
            $(nRow).addClass('highlight-row');
          }
          if (aData.verifiedIncomeProgram) {
            $(nRow).addClass('vip-row');
          }
        },
        buttons: buttons,
        search: {
          regex: true,
          smart: false
        },
        order: sortBy
      });

      this.formsTable.buttons()
          .container()
          .appendTo('#submittedFormsList_wrapper .col-sm-6:eq(0)');

      this.formsTable.on('search.dt', () => {
        this.UPDATE_FORM_FILTER_TERM(this.formsTable.search());
      });

      this.setupFormModal();
    },
    prettyPrint: function (value, type) {
      if (type === 'Date') {
        if (_.includes(value, 'T')) {
          return moment(value).format('MMMM Do YYYY h:mm A');
        } else if (!value || value === '') {
          return '';
        } else {
          return moment(value, "YYYY-MM-DD").format('DD-MM-YYYY');
        }
      } else if (type === 'Status') {
        return _.result(_.find(this.getStatuses, (status) => {
          return status.id === value;
        }), 'name') || '';
      } else if (type === 'Product') {
        return _.result(_.find(this.getProducts, (product) => {
          return product.id === value;
        }), 'name') || '';
      } else if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      } else if (type === 'Array') {
        return _.join(value, ', ');
      }
    },
    setupFormModal: function () {
      $('#submittedFormsList tbody').on('click', 'tr', (e) => {
        this.formDetails = this.formsTable.row(e.currentTarget).data();
        if (this.formDetails) {
          $("#formDetailsModal").modal('show');
        } else {
          this.formDetails = {};
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
