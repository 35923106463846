<template>
  <div id="dashboard-main">
    <div
      v-if="jobType !== 'BackOffice'"
      class="row"
    >
      <div class="col-lg-offset-2 col-lg-4 col-md-offset-2 col-md-4">
        <div class="panel info-box panel-white">
          <div class="panel-body">
            <div class="info-box-stats">
              <p class="counter">
                {{ getStats('status', 'PENDING') }}
              </p>
              <span class="info-box-title">Total Forms Pending</span>
            </div>
            <div class="info-box-icon">
              <i class="icon-action-undo" />
            </div>
            <div class="info-box-progress">
              <div class="progress progress-xs progress-squared bs-n">
                <div
                  :aria-valuenow="(Math.round((getStats('status', 'PENDING') / (getStats('status', 'VERIFIED') + getStats('status', 'PENDING'))) * 100))"
                  :style="{width: (Math.round((getStats('status', 'PENDING') / (getStats('status', 'VERIFIED') + getStats('status', 'PENDING'))) * 100)) + '%'}"
                  aria-valuemax="100"
                  aria-valuemin="0"
                  class="progress-bar progress-bar-danger"
                  role="progressbar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="panel info-box panel-white">
          <div class="panel-body">
            <div class="info-box-stats">
              <p class="counter">
                {{ getStats('status', 'VERIFIED') }}
              </p>
              <span class="info-box-title">Total Forms Verified</span>
            </div>
            <div class="info-box-icon">
              <i class="icon-user" />
            </div>
            <div class="info-box-progress">
              <div class="progress progress-xs progress-squared bs-n">
                <div
                  :aria-valuenow="(Math.round((getStats('status', 'VERIFIED') / (getStats('status', 'VERIFIED') + getStats('status', 'PENDING'))) * 100))"
                  :style="{width: (Math.round((getStats('status', 'VERIFIED') / (getStats('status', 'VERIFIED') + getStats('status', 'PENDING'))) * 100)) + '%'}"
                  aria-valuemax="100"
                  aria-valuemin="0"
                  class="progress-bar progress-bar-info"
                  role="progressbar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- Row -->
    <div class="row">
      <!--			<div class="col-md-6">-->
      <!--				<div class="panel panel-white">-->
      <!--					<div class="panel-heading">-->
      <!--						<h3 class="panel-title">Form Status</h3>-->
      <!--					</div>-->
      <!--					<div class="panel-body">-->
      <!--						<pie-chart :chart-data="statusChartData" :options="statusChartOptions"></pie-chart>-->
      <!--					</div>-->
      <!--				</div>-->
      <!--			</div>-->
      <div
        v-if="jobType !== 'BackOffice'"
        class="hidden-md hidden-sm hidden-xs col-lg-6"
      >
        <div class="panel panel-white">
          <div class="panel-heading">
            <h3 class="panel-title">
              Team Comparison - {{ (statusFilters.length > 0) ? '(' + statusFilters.join(', ') + ')' : 'All' }} Forms
            </h3>
          </div>
          <div class="panel-body">
            <doughnut-chart
              :chart-data="teamChartData"
              :options="teamChartOptions"
            />
          </div>
        </div>
      </div>
      <div :class="jobType !== 'BackOffice' ? 'col-lg-6' : 'col-xs-12'">
        <div class="panel panel-white">
          <div class="panel-heading">
            <h3 class="panel-title">
              Team Breakdown
            </h3>
          </div>
          <div class="panel-body">
            <TeamBreakdownTable
              :enabled="true"
              :forms="dashboardForms"
            />
          </div>
        </div>
      </div>
    </div><!-- Row -->
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import DoughnutChart from '../../../components/DoughnutChart';
import {chartColours} from "@/constants";
import TeamBreakdownTable from "../../../components/TeamBreakdownTable";

export default {
  name: "DashboardAdmin",
  components: {
    DoughnutChart,
    TeamBreakdownTable
  },
  data() {
    return {
      jobType: '',
      statusChartData: {
        labels: [],
        datasets: []
      },
      teamChartData: {
        labels: [],
        datasets: []
      },
      dashboardForms: [],
      statusChartOptions: {},
      teamChartOptions: {},
      statusFilters: []
    };
  },
  computed: {
    ...mapGetters([
      "getUsers",
      "getStatuses",
      "getForms",
      "getLoggedInUser",
      "getTitles"
    ]),
    ...mapState([
      'loggedInUser',
      'formFilterTerm',
      'forms',
      'updateSubscriptionForm',
      'deleteSubscriptionForm',
      'activeDashboardBranchId'
    ]),
    _getStatuses: function () {
      let statusList = [];
      if (this.getLoggedInUser) {
        const currentUserJobTitle = _.result(_.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle
        }), 'access');
        statusList = _.concat(statusList, _.filter(this.getStatuses, (status) => {
          return _.includes(status.daily, currentUserJobTitle);
        }));
        statusList = _.concat(statusList, _.filter(this.getStatuses, (status) => {
          return _.includes(status.alltime, currentUserJobTitle);
        }));
      }
      return statusList;
    },
    getStats() {
      return (groupBy, groupType) => {
        let count = 0;
        let groupByForms = _.groupBy(this.getForms, groupBy);
        let groupTypes;
        if (groupBy === 'status' && groupType === 'VERIFIED') {
          groupTypes = ["411ab033-6cba-44ce-b603-67c3080b9e2f", "60bf9d14-ea35-44a7-88eb-30ff2eaa1712", "5e1159c3-e54a-4c66-ae6f-0676522b4cb9"]; //NOTE: Hardcoded ids = Bad idea
        } else if (groupBy === 'status' && groupType === 'PENDING') {
          groupTypes = ["1fdca13d-adf6-411a-a002-a33e228d22df", "7795614b-720c-4581-9309-4f569895ac8d"]; //NOTE: Hardcoded ids = Bad idea
        } else {
          groupTypes = [groupType];
        }
        _.forEach(groupTypes, (group) => {
          let groupForms = groupByForms[group];
          if (groupForms && groupForms.length > 0) {
            count += groupForms.length;
          }
        });
        return count;
      }
    }
  },
  watch: {
    formFilterTerm(newValue) {
      if (newValue && newValue !== '') {
        this.statusFilters = newValue.split(' | ');
      } else {
        this.statusFilters = [];
      }
      this.updateChartData();
    },
    forms(newValue) {
      let newForms = _.differenceBy(newValue, this.dashboardForms, 'id');
      if (newForms.length > 0) {
        this.dashboardForms = _.concat(this.dashboardForms, newForms);
        this.updateChartData();
      }
    },
    updateSubscriptionForm(newValue) {
      let oldForms = _.cloneDeep(this.dashboardForms);
      let formIndex = _.findIndex(oldForms, (oldForm) => {
        return oldForm.id === newValue.id;
      });
      if (formIndex !== -1) {
        oldForms[formIndex] = newValue;
        this.dashboardForms = oldForms;
        this.updateChartData();
      }
    },
    deleteSubscriptionForm(newValue) {
      let oldForms = _.cloneDeep(this.dashboardForms);
      _.remove(oldForms, (oldForm) => {
        return oldForm.id === newValue.id;
      });
      this.dashboardForms = oldForms;
      this.updateChartData();
    },
    activeDashboardBranchId(newValue) {
      if (newValue && newValue !== '') {
        this.dashboardForms = [];
        this.updateChartData();
      }
    }
  },
  mounted() {
    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          this.jobType = jobTitle.access;
        }
      }
    }
    this.dashboardForms = this.getForms;
    this.updateChartData();
  },
  methods: {
    getStatusLabel: function (status, format) {
      if (format === 'Capitalize') {
        return _.capitalize(status);
      } else if (format === 'Uppercase') {
        return _.upperCase(status);
      } else if (format === 'Underscore') {
        return status.replace(/ /g, "_");
      } else if (format === 'StartCase') {
        return _.startCase(_.lowerCase(status));
      }
    },
    prettyPrint: function (value, type) {
      if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      }
    },
    updateChartData: function () {
      //let statusGroups = _.groupBy(this.dashboardForms, 'status');
      let teamGroups = _.groupBy(this.dashboardForms, 'owner');

      // if (_.size(statusGroups) > 0) {
      // 	let labels = [];
      // 	let data = [];
      // 	let backgroundColours = [];
      // 	let colourIndex = 0;
      // 	let colourIndexStep = Math.floor(chartColours.length / _.size(statusGroups));
      // 	colourIndexStep = colourIndexStep < 1 ? 1 : colourIndexStep;
      // 	_.forEach(statusGroups, (forms, status) => {
      // 		labels.push(status);
      // 		data.push(forms.length);
      // 		backgroundColours.push(chartColours[colourIndex % chartColours.length]);
      // 		colourIndex = colourIndex + colourIndexStep;
      // 	});
      // 	this.statusChartData = {
      // 		labels: labels,
      // 		datasets: [
      // 			{
      // 				backgroundColor: backgroundColours,
      // 				data: data
      // 			}
      // 		]
      // 	};
      // 	this.statusChartOptions = {
      // 		responsive: true,
      // 		plugins: {
      // 			datalabels: {
      // 				color: '#fff',
      // 			}
      // 		}
      // 	}
      // }
      if (_.size(teamGroups) > 0) {
        let labels = [];
        let data = [];
        let backgroundColours = [];
        let borderColours = [];
        let colourIndex = 0;
        let colourIndexStep = Math.floor(chartColours.length / _.size(teamGroups));
        colourIndexStep = colourIndexStep < 1 ? 1 : colourIndexStep;
        _.forEach(teamGroups, (forms, member) => {
          if (this.statusFilters.length > 0) {
            let filteredForms = _.filter(forms, (form) => {
              let formStatus = _.find(this.getStatuses, (status) => {
                return status.id === form.status;
              });
              return _.includes(this.statusFilters, formStatus.name);
            });
            if (filteredForms.length > 0) {
              data.push(filteredForms.length);
              labels.push(this.prettyPrint(member, 'User'));
            }
          } else {
            labels.push(this.prettyPrint(member, 'User'));
            data.push(forms.length);
          }
          backgroundColours.push(chartColours[colourIndex % chartColours.length].color);
          borderColours.push(chartColours[colourIndex % chartColours.length].border);
          colourIndex = colourIndex + colourIndexStep;
        });
        this.teamChartData = {
          labels: labels,
          datasets: [
            {
              backgroundColor: backgroundColours,
              borderColor: borderColours,
              data: data
            }
          ]
        };
        this.teamChartOptions = {
          responsive: true,
          tooltips: {
            callbacks: {
              title: (t, d) => {
                let toolTip = '';
                _.forEach(teamGroups, (forms, group) => {
                  if (this.prettyPrint(group, 'User') === d.labels[t[0].index]) {
                    let statusGroups = _.groupBy(forms, 'status');
                    _.forEach(statusGroups, (forms, status) => {
                      let statusData = _.find(this.getStatuses, (statusObj) => {
                        return statusObj.id === status;
                      });
                      if (toolTip === '') {
                        toolTip = statusData.name + ': ' + forms.length;
                      } else {
                        toolTip += '\n' + statusData.name + ': ' + forms.length;
                      }
                    })
                  }
                });
                return toolTip;
              }
            }
          },
          plugins: {
            datalabels: {
              color: '#fff',
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
