<template>
  <div>
    <div class="page-title">
      <div class="row">
        <div class="col-xs-6">
          <h3>Dashboard</h3>
          <div class="page-breadcrumb">
            <ol class="breadcrumb">
              <li>
                <router-link
                  :to="{name: 'home'}"
                >
                  Home
                </router-link>
              </li>
              <li class="active">
                Dashboard
              </li>
            </ol>
          </div>
        </div>
        <div
          v-if="jobType === 'Owner'"
          class="col-xs-6 col-sm-4 col-md-3 col-lg-2 float-right"
        >
          <label class="has-float-label">
            <VueMultiselect
              v-model="activeBranchId"
              :allow-empty="false"
              :multiple="false"
              :options="_getBranches"
              :placeholder="null"
              :show-labels="false"
              label="name"
              track-by="id"
            />
            <span class="float-label">Branch: </span>
          </label>
        </div>
        <div
          v-if="jobType === 'User'"
          class="col-xs-2 text-right float-right"
        >
          <button
            id="notificationsBell"
            :class="notificationsActive ? 'notifications-enabled' : 'notifications-disabled'"
            class="btn btn-link"
            @click.stop.prevent="toggleNotifications"
          >
            <span class="menu-icon fa-2x fas fa-bell" />
          </button>
        </div>
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row m-b-lg">
        <h1 class="text-center">
          <vue-typer
            :repeat="0"
            :text="getSalutation || ' '"
            caret-animation="smooth"
          />
        </h1>
      </div>
      <active-lead
        v-if="jobType === 'User'"
        class="row"
      />
      <div
        v-if="jobType !== 'Manager' && jobType !== 'Supervisor' && jobType !== 'Owner'"
        class="row"
      >
        <div class="col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8 col-xs-12">
          <div
            class="panel info-box panel-white"
            style="overflow: visible"
          >
            <div
              class="panel-heading clearfix"
              style="overflow: visible"
            >
              <div
                class="col-md-4 hidden-sm hidden-xs"
                style="padding-left: 0 !important;"
              >
                <vue-typer
                  :repeat="0"
                  :text="activeMtdStatus.name || ' '"
                  caret-animation="smooth"
                />
              </div>
              <div
                v-if="jobType !== 'Manager' && jobType !== 'Supervisor' && jobType !== 'Owner'"
                class="float-right"
              >
                <button
                  v-show="autoPlayStats !== null"
                  class="btn"
                  style="background-color: #e9edf2"
                  @click.stop.prevent="autoPlayStatsChange(false)"
                >
                  <span class="menu-icon fas fa-pause" />
                </button>
                <button
                  v-show="autoPlayStats === null"
                  class="btn"
                  style="background-color: #e9edf2"
                  @click.stop.prevent="autoPlayStatsChange(true)"
                >
                  <span class="menu-icon fas fa-play" />
                </button>
              </div>
              <div
                v-if="jobType !== 'Manager' && jobType !== 'Supervisor' && jobType !== 'Owner'"
                class="float-right"
              >
                <button
                  class="btn"
                  style="background-color: #e9edf2"
                  @click.stop.prevent="UPDATE_REFRESH_MTD_FORMS(true)"
                >
                  <span class="menu-icon fas fa-sync" />
                </button>
              </div>
              <div
                v-if="jobType !== 'Manager' && jobType !== 'Supervisor' && jobType !== 'Owner'"
                class="col-xs-7 col-md-5 col-lg-4 float-right"
              >
                <label class="has-float-label no-margin-bottom">
                  <VueMultiselect
                    v-model="activeMtdStatus"
                    :allow-empty="false"
                    :multiple="false"
                    :options="_getMtdStatuses"
                    :placeholder="null"
                    :show-labels="false"
                    label="name"
                    track-by="id"
                  />
                </label>
              </div>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <div class="info-box-stats">
                    <p class="counter">
                      {{ getDailyStats }}
                    </p>
                    <span class="info-box-title">Today</span>
                  </div>
                  <div class="info-box-icon">
                    <i class="icon-like" />
                  </div>
                  <div class="info-box-progress">
                    <div class="progress progress-md progress-striped bs-n">
                      <div
                        :aria-valuenow="getPercentageForStatus('Daily')"
                        :style="{width: getPercentageForStatus('Daily') + '%'}"
                        aria-valuemax="100"
                        aria-valuemin="0"
                        class="progress-bar progress-bar-info"
                        role="progressbar"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <div class="info-box-stats">
                    <p class="counter">
                      {{ getMtdStats }}
                    </p>
                    <span class="info-box-title">{{ getCurrentMonth }}</span>
                  </div>
                  <div class="info-box-icon">
                    <i class="icon-like" />
                  </div>
                  <div class="info-box-progress">
                    <div class="progress progress-md progress-striped bs-n">
                      <div
                        :aria-valuenow="getPercentageForStatus('Mtd')"
                        :style="{width: getPercentageForStatus('Mtd') + '%'}"
                        aria-valuemax="100"
                        aria-valuemin="0"
                        class="progress-bar progress-bar-info"
                        role="progressbar"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <div
            v-for="rowIdx in Math.ceil(_getAllTimeStatuses.length / 6)"
            :key="'allTime-card-' + rowIdx"
            class="row"
          >
            <div
              v-for="(status, index) in _getAllTimeStatusesForRow(rowIdx)"
              :key="status.id"
              :class="(_getAllTimeStatusesForRow(rowIdx).length % 6 === 0 || index !== 0) ? 'col-md-offset-0' : 'col-md-offset-' + (6 - (_getAllTimeStatusesForRow(rowIdx).length % 6))"
              class="col-md-2 col-sm-4 col-xs-6"
            >
              <div
                :id="getStatusLabel(status.name, 'Underscore') + '_CARD'"
                :class="{'activeFilterCard': getFilterCardActive(status.name)}"
                class="panel info-box panel-white"
                @click="filterForms(status.name)"
              >
                <div class="panel-body">
                  <div class="info-box-stats">
                    <p class="counter">
                      {{ getAllTimeStats('status', status.id) }} <span
                        class="text-muted"
                        style="font-size: 20px"
                      >({{ getPercentageForStatus('AllTime', status.id) + '%' }})</span>
                    </p>
                    <span class="info-box-title">{{ getStatusLabel(status.name, 'StartCase') }}</span>
                  </div>
                  <div class="info-box-icon">
                    <i class="icon-like" />
                  </div>
                  <div class="info-box-progress">
                    <div class="progress progress-xs progress-squared bs-n">
                      <div
                        :aria-valuenow="getPercentageForStatus('AllTime', status.id)"
                        :style="{width: getPercentageForStatus('AllTime', status.id) + '%'}"
                        aria-valuemax="100"
                        aria-valuemin="0"
                        class="progress-bar progress-bar-danger"
                        role="progressbar"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <DashboardAdmin v-if="jobType === 'Owner' || jobType === 'Partner' || jobType === 'BackOffice'" />
      <DashboardBranchManager
        v-else-if="jobType === 'Manager' || jobType === 'Supervisor'"
        :job-type="jobType"
      />
      <div
        v-if="jobType !== 'Manager' && jobType !== 'Supervisor'"
        class="row"
      >
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                Submitted Forms
              </h4>
            </div>
            <div class="panel-body">
              <dashboard-forms />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import ActiveLead from "../../components/ActiveLead";
import DashboardForms from "./_components/DashboardForms";
import DashboardAdmin from "./_components/DashboardAdmin";
import DashboardBranchManager from "./_components/DashboardBranchManager";
import VueMultiselect from "vue-multiselect";
import {VueTyper} from 'vue-typer';
import {pushNotificationCategories} from "@/constants";

export default {
  name: "Dashboard",
  components: {
    ActiveLead,
    DashboardForms,
    DashboardAdmin,
    DashboardBranchManager,
    VueMultiselect,
    VueTyper
  },
  data() {
    return {
      jobType: '',
      activeBranchId: null,
      activeMtdStatus: {},
      activeLead: null,
      autoPlayStats: null,
      notificationsActive: false
    };
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getTitles",
      "getStatuses",
      "getBranches",
      "getForms",
      "getDailyForms",
      "getMtdForms",
      "getFormFilterTerm",
      "getActiveDashboardBranchId"
    ]),
    ...mapState([
      'loggedInUser'
    ]),
    getSalutation() {
      if (this.getLoggedInUser && this.getLoggedInUser.birthDate && moment().format('MM-DD') === moment(this.getLoggedInUser.birthDate, 'YYYY-MM-DD').format('MM-DD')) {
        return 'Happy Birthday ' + (this.getLoggedInUser ? this.getLoggedInUser.firstName : '') + '!';
      }
      let salutation = 'Good Morning, ' + (this.getLoggedInUser ? this.getLoggedInUser.firstName : '') + ". Here is today's activity!";
      if (parseFloat(moment().format('HH')) >= 17) {
        salutation = 'Good Evening, ' + (this.getLoggedInUser ? this.getLoggedInUser.firstName : '') + ". Here is today's activity!";
      } else if (parseFloat(moment().format('HH')) >= 12) {
        salutation = 'Good Afternoon, ' + (this.getLoggedInUser ? this.getLoggedInUser.firstName : '') + ". Here is today's activity!";
      }
      return salutation;
    },
    _getAllTimeStatuses: function () {
      let statusList = [];
      if (this.getLoggedInUser) {
        const currentUserJobTitle = _.result(_.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle
        }), 'access');
        statusList = _.concat(statusList, _.filter(this.getStatuses, (status) => {
          return _.includes(status.alltime, currentUserJobTitle);
        }));
      }
      return _.sortBy(statusList, 'workflow');
    },
    _getAllTimeStatusesForRow() {
      return (rowIdx) => {
        return this._getAllTimeStatuses.slice((rowIdx - 1) * 6, rowIdx * 6)
      };
    },
    _getMtdStatuses: function () {
      let statusList = [];
      if (this.getLoggedInUser) {
        const currentUserJobTitle = _.result(_.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle
        }), 'access');
        statusList = _.concat(statusList, _.filter(this.getStatuses, (status) => {
          return _.includes(status.currentMonth, currentUserJobTitle);
        }));
      }
      return _.sortBy(statusList, 'workflow');
    },
    _getBranches: function () {
      return _.sortBy(this.getBranches, 'name');
    },
    getAllTimeStats() {
      return (groupBy, groupType) => {
        let count = 0;
        let groupByForms = _.groupBy(this.getForms, groupBy);
        let groupTypes;
        if (groupBy === 'status' && groupType === 'VERIFIED') {
          groupTypes = ["411ab033-6cba-44ce-b603-67c3080b9e2f", "60bf9d14-ea35-44a7-88eb-30ff2eaa1712", "5e1159c3-e54a-4c66-ae6f-0676522b4cb9"]; //NOTE: Hardcoded ids = Bad idea
        } else if (groupBy === 'status' && groupType === 'PENDING') {
          groupTypes = ["1fdca13d-adf6-411a-a002-a33e228d22df", "7795614b-720c-4581-9309-4f569895ac8d"]; //NOTE: Hardcoded ids = Bad idea
        } else {
          groupTypes = [groupType];
        }
        _.forEach(groupTypes, (group) => {
          let groupForms = groupByForms[group];
          if (groupForms && groupForms.length > 0) {
            count += groupForms.length;
          }
        });
        return count;
      }
    },
    getPercentageForStatus() {
      return (type, statusId) => {
        if (type === 'AllTime') {
          return (Math.round((isNaN(this.getAllTimeStats('status', statusId) / this.getForms.length) ? 0 : (this.getAllTimeStats('status', statusId) / this.getForms.length)) * 100));
        } else if (type === 'Mtd') {
          return (Math.round((isNaN(this.getMtdStats / this.getMtdForms.length) ? 0 : (this.getMtdStats / this.getMtdForms.length)) * 100));
        } else if (type === 'Daily') {
          return (Math.round((isNaN(this.getDailyStats / this.getMtdForms.length) ? 0 : (this.getDailyStats / this.getMtdForms.length)) * 100));
        } else {
          return 0;
        }
      }
    },
    getDailyStats() {
      let groupByForms = _.groupBy(this.getDailyForms, 'status');
      let groupForms = groupByForms[this.activeMtdStatus.id];
      if (groupForms && groupForms.length > 0) {
        return groupForms.length;
      } else {
        return 0;
      }
    },
    getMtdStats() {
      let groupByForms = _.groupBy(this.getMtdForms, 'status');
      let groupForms = groupByForms[this.activeMtdStatus.id];
      if (groupForms && groupForms.length > 0) {
        return groupForms.length;
      } else {
        return 0;
      }
    },
    getFilterCardActive() {
      return (filter) => {
        let cardActive = false;
        _.forEach(_.split(this.getFormFilterTerm, '|'), (term) => {
          if (_.trim(term) === filter) {
            cardActive = true;
            return false;
          }
        });
        return cardActive;
      }
    },
    getCurrentMonth() {
      return moment().format('MMMM');
    }
  },
  watch: {
    loggedInUser(newValue) {
      if (newValue && newValue !== '') {
        if (newValue.jobTitle && newValue.jobTitle !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === newValue.jobTitle;
          });
          if (jobTitle) {
            this.jobType = jobTitle.access;
            let mtdStatuses = _.filter(this.getStatuses, (status) => {
              return _.includes(status.currentMonth, this.jobType);
            });
            if (mtdStatuses && mtdStatuses.length > 0) {
              this.activeMtdStatus = mtdStatuses[0];
            } else {
              this.activeMtdStatus = {};
            }
          }
        }
        this.activeBranchId = _.find(this.getBranches, (branch) => {
          return branch.number === (this.getActiveDashboardBranchId ? this.getActiveDashboardBranchId : newValue.branchId[0]);
        });
      }
    },
    activeBranchId(newValue) {
      if (newValue && newValue !== '' && newValue.number !== this.getActiveDashboardBranchId) {
        this.UPDATE_ACTIVE_DASHBOARD_BRANCH_ID(newValue.number);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    let formModal = $("#formDetailsModal");
    if ((formModal.data('bs.modal') || {}).isShown) {
      formModal.modal('hide');
      document.getElementById('submittedFormsList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else {
      $('.activeFilterCard').removeClass('activeFilterCard');
      this.UPDATE_FORM_FILTER_TERM('');
      next();
    }
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');
    let pushSubscriptionObject = JSON.parse(window.localStorage.getItem('pushSubscription' + pushNotificationCategories.FORMS));
    this.notificationsActive = pushSubscriptionObject && pushSubscriptionObject.endpoint && pushSubscriptionObject.endpoint !== '';

    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          this.jobType = jobTitle.access;
          let mtdStatuses = _.filter(this.getStatuses, (status) => {
            return _.includes(status.currentMonth, this.jobType);
          });
          if (mtdStatuses && mtdStatuses.length > 0) {
            this.activeMtdStatus = mtdStatuses[0];
          } else {
            this.activeMtdStatus = {};
          }
        }
      }
      this.activeBranchId = _.find(this.getBranches, (branch) => {
        return branch.number === (this.getActiveDashboardBranchId ? this.getActiveDashboardBranchId : this.getLoggedInUser.branchId[0]);
      });

      this.autoPlayStats = setInterval(() => {
        let index = _.findIndex(this._getMtdStatuses, (status) => {
          return status.id === this.activeMtdStatus.id;
        });
        if (index !== -1) {
          index++;
          if (index >= this._getMtdStatuses.length) {
            index = 0;
          }
          this.activeMtdStatus = this._getMtdStatuses[index];
        }
      }, 5000);
    }
  },
  methods: {
    ...mapMutations([
      "UPDATE_FORM_FILTER_TERM",
      "UPDATE_ACTIVE_DASHBOARD_BRANCH_ID",
      "UPDATE_REFRESH_MTD_FORMS",
      "UPDATE_LOGGED_IN_USER"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    getStatusLabel: function (status, format) {
      if (format === 'Capitalize') {
        return _.capitalize(status);
      } else if (format === 'Uppercase') {
        return _.upperCase(status);
      } else if (format === 'Underscore') {
        return status.replace(/ /g, "_");
      } else if (format === 'StartCase') {
        return _.startCase(_.lowerCase(status));
      }
    },
    filterForms: function (statusType) {
      const currentTerm = this.getFormFilterTerm;
      let filterCard = $('#' + this.getStatusLabel(statusType, 'Underscore') + '_CARD');
      if (filterCard.hasClass('activeFilterCard')) {
        let currentTermChunks = currentTerm.split(' | ');
        _.remove(currentTermChunks, (n) => {
          return n === statusType;
        });
        this.UPDATE_FORM_FILTER_TERM(currentTermChunks.join(' | '));
      } else {
        if (currentTerm && currentTerm !== '') {
          this.UPDATE_FORM_FILTER_TERM(currentTerm + ' | ' + statusType);
        } else {
          this.UPDATE_FORM_FILTER_TERM(statusType)
        }
      }
    },
    autoPlayStatsChange: function (turnOn) {
      if (turnOn && this.autoPlayStats === null) {
        this.autoPlayStats = setInterval(() => {
          let index = _.findIndex(this._getMtdStatuses, (status) => {
            return status.id === this.activeMtdStatus.id;
          });
          if (index !== -1) {
            index++;
            if (index >= this._getMtdStatuses.length) {
              index = 0;
            }
            this.activeMtdStatus = this._getMtdStatuses[index];
          }
        }, 5000);
      } else {
        clearInterval(this.autoPlayStats);
        this.autoPlayStats = null;
      }
    },
    async toggleNotifications() {
      if (!navigator.serviceWorker) {
        this.setMessage('v-notify-error', "Error!", 'Something went wrong! Please try again later.');
        return;
      }
      let registrations = await navigator.serviceWorker.getRegistrations();
      if (registrations.length < 1) {
        console.error('Error fetching service worker.', 'Service worker not registered.');
        this.setMessage('v-notify-error', "Error!", 'Something went wrong! Please try again later.');
        return;
      }
      let notificationsButton = $('#notificationsBell');
      notificationsButton.attr('disabled', true);
      if (this.notificationsActive) {
        try {
          let pushSubscriptions = _.filter(this.getLoggedInUser.pushSubscriptions, (subscription) => {
            let subscriptionObject = JSON.parse(subscription) || {};
            return subscriptionObject.type !== pushNotificationCategories.FORMS;
          });
          if (pushSubscriptions.length < 1) {
            pushSubscriptions = null;
          }
          await API.graphql(graphqlOperation(mutations.updateUser, {
            input: {
              id: this.getLoggedInUser.id,
              pushSubscriptions: pushSubscriptions
            }
          }));
          window.localStorage.removeItem('pushSubscription' + pushNotificationCategories.FORMS);
          let updatedUser = _.cloneDeep(this.getLoggedInUser);
          updatedUser.pushSubscriptions = pushSubscriptions;
          this.UPDATE_LOGGED_IN_USER(updatedUser);
          this.setMessage('v-notify-success', 'Done!', "Notifications are now disabled!");
          this.notificationsActive = false;
        } catch (error) {
          // Unsubscription failed
          console.error('Unable to unsubscribe from push.', error);
          this.setMessage('v-notify-error', "Error!", 'Something went wrong! Please try again later.');
        } finally {
          notificationsButton.attr('disabled', false);
        }
      } else {
        try {
          let serviceWorkerRegistration = await navigator.serviceWorker.ready;
          try {
            let options = {
              userVisibleOnly: true,
              applicationServerKey: 'BP8qzHt90VAyEoOziUULbe2BmqbysxNPVg9xfGsjLsKXNEG_YnX6rJ8A7zVqzRWj-36obFN9N61tiCq_H69mMAA'
            };
            let pushSubscription = await serviceWorkerRegistration.pushManager.subscribe(options);
            let currentObject = pushSubscription.toJSON();
            window.localStorage.setItem('pushSubscription' + pushNotificationCategories.FORMS, JSON.stringify(currentObject));
            let serverObject = JSON.parse(_.find(this.getLoggedInUser.pushSubscriptions, (subscription) => {
              let subscriptionObject = JSON.parse(subscription) || {};
              return subscriptionObject.type === pushNotificationCategories.FORMS;
            }) || null);
            if (!serverObject || currentObject.endpoint !== serverObject.endpoint || currentObject.keys['p256dh'] !== serverObject.keys['p256dh'] || currentObject.keys.auth !== serverObject.keys.auth) {
              currentObject.type = pushNotificationCategories.FORMS;
              let pushSubscriptions = _.filter(this.getLoggedInUser.pushSubscriptions, (subscription) => {
                let subscriptionObject = JSON.parse(subscription) || {};
                return subscriptionObject.type !== pushNotificationCategories.FORMS;
              });
              pushSubscriptions.push(JSON.stringify(currentObject));
              await API.graphql(graphqlOperation(mutations.updateUser, {
                input: {
                  id: this.getLoggedInUser.id,
                  pushSubscriptions: pushSubscriptions
                }
              }));
              let updatedUser = _.cloneDeep(this.getLoggedInUser);
              updatedUser.pushSubscriptions = pushSubscriptions;
              this.UPDATE_LOGGED_IN_USER(updatedUser);
            }
            this.setMessage('v-notify-success', 'All Set!', "Notifications enabled successfully.");
            this.notificationsActive = true;
          } catch (error) {
            console.log("Error during subscription");
            console.log(error);
            if (Notification.permission === 'denied') {
              console.warn('Permission for notifications was denied');
              this.setMessage('v-notify-error', "Error!", 'Notifications are disabled! Please enable them from your browser.');
            } else {
              console.error('Unable to subscribe to push', error);
              this.setMessage('v-notify-error', "Error!", 'Unable to subscribe to notifications at this time! Try again later.');
            }
          }
        } catch (error) {
          console.error("Error during fetching service worker", error);
          this.setMessage('v-notify-error', "Error!", 'Something went wrong! Please try again later.');
        } finally {
          notificationsButton.attr('disabled', false);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
