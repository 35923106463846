<script>
import {Doughnut, mixins} from 'vue-chartjs';

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

<style scoped>

</style>
