<template>
  <div class="table-responsive">
    <table
      id="teamStatisticsList"
      class="table table-striped table-bordered"
      style="width:100%"
    >
      <thead>
        <tr>
          <th class="isVisible">
            Name
          </th>
          <th>Supervisor</th>
          <th class="isVisible">
            Total
          </th>
          <th
            v-for="(status) in _getStatuses"
            :id="status.id"
            :key="status.id"
            class="status-column"
          >
            {{ status.name }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TeamBreakdownTable",
  props: {
    enabled: {
      type: Boolean,
      required: true
    },
    forms: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      table: null,
      tableData: []
    }
  },
  computed: {
    ...mapGetters([
      "getUsers",
      "getStatuses"
    ]),
    _getStatuses() {
      return _.sortBy(this.getStatuses, 'name');
    },
  },
  watch: {
    forms() {
      if (this.enabled) {
        this.table.clear().draw();
        this.updateTeamTableData();
      }
    },
    enabled(newValue) {
      if (newValue) {
        this.table.clear().draw();
        this.updateTeamTableData();
      }
    },
  },
  mounted() {
    this.createTeamTable();
  },
  methods: {
    prettyPrint: function (value, type) {
      if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      }
    },
    createTeamTable: function () {
      this.table = $('#teamStatisticsList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.tableData,
        rowId: function (a) {
          return a.name + a.supervisor;
        },
        // rowGroup: {              //TODO: rowGroup extension currently broken (https://datatables.net/forums/discussion/54912/rowgroup-1-1-0-referenceerror-that-is-not-defined-when-datasrc-is-null)
        // 	dataSrc: 1
        // },
        columns: [
          {
            name: 'owner',
            data: 'name'
          },
          {
            name: 'supervisor',
            data: 'supervisor',
            defaultContent: ""
          },
          {
            name: 'total',
            data: 'TOTAL',
            defaultContent: ""
          },
          ..._.map(this._getStatuses, (status) => {
            return {
              name: status.id,
              data: status.id,
              defaultContent: ""
            }
          })
        ],
        columnDefs: [
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: [0, 1]
          }
        ],
        buttons: [
          {
            extend: 'pageLength',
            text: '<i class="fas fa-filter"></i>',
            titleAttr: 'Number of Rows'
          },
          {
            extend: 'excelHtml5',
            autoFilter: true,
            text: '<i class="far fa-file-excel"></i>',
            titleAttr: 'Excel',
            exportOptions: {
              columns: [1, ':visible']
            }
          }
        ],
        search: {
          regex: true,
          smart: false
        },
        order: [[1, "asc"], [0, "asc"]]
      });

      this.table.buttons()
          .container()
          .appendTo('#teamStatisticsList_wrapper .col-sm-6:eq(0)');
    },
    updateTeamTableData: function () {
      if (!this.enabled) {
        return;
      }
      let initTableData = [];
      let supervisorData = _.groupBy(this.forms, 'supervisor');
      let visibleStatuses = '';
      _.forEach(supervisorData, (teamForms, supervisor) => {
        let teamData = _.groupBy(teamForms, 'owner');
        _.forEach(teamData, (userForms, user) => {
          let userTableRow = {
            'name': user,
            'supervisor': supervisor,
            'TOTAL': userForms.length
          };
          let userData = _.groupBy(userForms, 'status');
          _.forEach(userData, (forms, formStatus) => {
            if (visibleStatuses === '') {
              visibleStatuses = '#' + formStatus;
            } else {
              visibleStatuses += ', #' + formStatus;
            }
            userTableRow[formStatus] = forms.length;
          });
          initTableData.push(userTableRow);
        });
      });
      this.tableData = initTableData;
      this.table.clear();
      this.table.rows.add(this.tableData);
      this.table.columns().visible(false);
      if (visibleStatuses !== '') {
        this.table.columns('.isVisible').visible(true);
        this.table.columns(visibleStatuses).visible(true);
        this.table.columns.adjust().draw();
      }
      this.table.draw();
    }
  }
}
</script>

<style scoped>

</style>
