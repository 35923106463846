<template>
  <div id="dashboard-main">
    <div class="row">
      <div class="col-md-offset-2 col-md-4">
        <div class="panel info-box panel-white">
          <div class="panel-body">
            <div class="info-box-stats">
              <p class="counter">
                {{ getStats('PENDING') }}
              </p>
              <span class="info-box-title">Total Forms Pending</span>
            </div>
            <div class="info-box-icon">
              <i class="icon-action-undo" />
            </div>
            <div class="info-box-progress">
              <div class="progress progress-xs progress-squared bs-n">
                <div
                  :aria-valuenow="(Math.round((getStats('PENDING') / (_getForms.length)) * 100))"
                  :style="{width: (Math.round((getStats('PENDING') / (_getForms.length)) * 100)) + '%'}"
                  aria-valuemax="100"
                  aria-valuemin="0"
                  class="progress-bar progress-bar-danger"
                  role="progressbar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="panel info-box panel-white">
          <div class="panel-body">
            <div class="info-box-stats">
              <p class="counter">
                {{ getStats('VERIFIED') }}
              </p>
              <span class="info-box-title">Total Forms Verified</span>
            </div>
            <div class="info-box-icon">
              <i class="icon-user" />
            </div>
            <div class="info-box-progress">
              <div class="progress progress-xs progress-squared bs-n">
                <div
                  :aria-valuenow="(Math.round((getStats('VERIFIED') / (_getForms.length)) * 100))"
                  :style="{width: (Math.round((getStats('VERIFIED') / (_getForms.length)) * 100)) + '%'}"
                  aria-valuemax="100"
                  aria-valuemin="0"
                  class="progress-bar progress-bar-info"
                  role="progressbar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- Row -->
    <div class="row">
      <div class="col-xs-12">
        <div class="panel panel-white">
          <div class="panel-heading">
            <h3 class="panel-title">
              Team Breakdown
            </h3>
          </div>
          <div class="panel-body">
            <div class="table-responsive">
              <table
                id="teamStatisticsList"
                class="table table-striped table-bordered"
                style="width:100%"
              >
                <thead>
                  <tr>
                    <th class="name">
                      Name
                    </th>
                    <th class="total">
                      Total
                    </th>
                    <th
                      v-for="(status) in _getStatuses"
                      :id="status.id"
                      :key="status.id"
                      class="status-column"
                    >
                      {{ status.name }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div><!-- Row -->
    <div class="row">
      <div class="col-xs-12">
        <div class="panel panel-white">
          <div class="panel-heading">
            <h3 class="panel-title">
              Team Comparison
            </h3>
          </div>
          <div class="panel-body">
            <bar-chart
              :chart-data="teamChartData"
              :options="teamChartOptions"
            />
          </div>
        </div>
      </div>
    </div><!-- Row -->
    <div
      v-show="enableHourlyChart"
      class="row"
    >
      <div class="col-xs-12">
        <div class="panel panel-white">
          <div class="panel-heading">
            <h3 class="panel-title">
              Hourly Comparison
            </h3>
          </div>
          <div class="panel-body">
            <bar-chart
              :chart-data="hourlyChartData"
              :options="hourlyChartOptions"
            />
          </div>
        </div>
      </div>
    </div><!-- Row -->

    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-white">
          <div class="panel-heading clearfix">
            <h2 class="panel-title text-center">
              Forms
            </h2>
          </div>
          <div class="panel-body">
            <div class="table-responsive">
              <table
                id="dashboardFormsList"
                class="table table-striped table-bordered"
                style="width:100%"
              >
                <thead>
                  <tr>
                    <th class="supervisor includeInExport">
                      Supervisor
                    </th>
                    <th class="owner includeInExport">
                      Owner
                    </th>
                    <th class="status includeInExport">
                      Status
                    </th>
                    <th class="firstName includeInExport">
                      First Name
                    </th>
                    <th class="lastName includeInExport">
                      Last Name
                    </th>
                    <th class="updatedAt includeInExport">
                      Updated On
                    </th>
                    <th class="updatedAtTime includeInExport">
                      Updated Time
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div><!-- Row -->
    <AIPFormModal
      :form-details="formDetails"
      :job-type="jobType"
      :show-all-fields="false"
      :show-history="false"
    />
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import BarChart from '../../../components/BarChart';
import {barChartColours} from "@/constants";
import AIPFormModal from "../../../components/AIPFormModal";

export default {
  name: "DashboardBranchManager",
  components: {
    BarChart,
    AIPFormModal
  },
  props: {
    jobType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      teamChartData: {
        labels: [],
        datasets: []
      },
      teamChartOptions: {},
      hourlyChartData: {
        labels: [],
        datasets: []
      },
      hourlyChartOptions: {},
      enableHourlyChart: false,
      dashboardForms: [],
      teamTableData: [],
      teamTable: null,
      statusColours: {},
      formDetails: {},
      formsTableData: [],
      formsTable: null
    }
  },
  beforeRouteLeave(to, from, next) {
    let formModal = $("#formDetailsModal");
    if ((formModal.data('bs.modal') || {}).isShown) {
      formModal.modal('hide');
      document.getElementById('dashboardFormsList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      "getUsers",
      "getTitles",
      "getStatuses",
      "getForms",
      "getLoggedInUser"
    ]),
    ...mapState([
      'forms',
      'updateSubscriptionForm',
      'deleteSubscriptionForm'
    ]),
    _getStatuses() {
      return _.sortBy(_.filter(this.getStatuses, (status) => {
        return _.includes(status.alltime, this.jobType)
            || _.includes(['a9525cb3-d038-4eae-8236-27e2e056597b', '411ab033-6cba-44ce-b603-67c3080b9e2f', '60bf9d14-ea35-44a7-88eb-30ff2eaa1712', '5e1159c3-e54a-4c66-ae6f-0676522b4cb9'], status.id)
      }), 'name');
    },
    _getForms() {
      return _.filter(this.dashboardForms, (form) => {
        let status = _.find(this.getStatuses, (status) => {
          return status.id === form.status;
        });
        return _.includes(status.alltime, this.jobType)
            || (form.bankVerificationEpoch && form.bankVerificationEpoch > parseInt(moment().startOf('day').format('X')));
      })
    },
    getStats() {
      return (groupType) => {
        let count = 0;
        let groupByForms = _.groupBy(this._getForms, 'status');
        let pendingGroupTypes = ["1fdca13d-adf6-411a-a002-a33e228d22df", "7795614b-720c-4581-9309-4f569895ac8d"]; //NOTE: Hardcoded ids = Bad idea
        if (groupType === 'PENDING') {
          _.forEach(pendingGroupTypes, (group) => {
            let groupForms = groupByForms[group];
            if (groupForms && groupForms.length > 0) {
              count += groupForms.length;
            }
          });
        } else if (groupType === 'VERIFIED') {
          _.forEach(groupByForms, (groupForms, group) => {
            if (!_.includes(pendingGroupTypes, group)) {
              count += groupForms.length;
            }
          })
        }
        return count;
      }
    }
  },
  watch: {
    forms(newValue) {
      let newForms = _.differenceBy(newValue, this.dashboardForms, 'id');
      if (newForms.length > 0) {
        this.dashboardForms = _.concat(this.dashboardForms, newForms);
        this.updateTeamTableData();
      }
    },
    updateSubscriptionForm(newValue) {
      let oldForms = _.cloneDeep(this.dashboardForms);
      let formIndex = _.findIndex(oldForms, (oldForm) => {
        return oldForm.id === newValue.id;
      });
      if (formIndex !== -1) {
        oldForms[formIndex] = newValue;
        this.dashboardForms = oldForms;
        this.updateTeamTableData();
      }
    },
    deleteSubscriptionForm(newValue) {
      let oldForms = _.cloneDeep(this.dashboardForms);
      _.remove(oldForms, (oldForm) => {
        return oldForm.id === newValue.id;
      });
      this.dashboardForms = oldForms;
      this.updateTeamTableData();
    },
  },
  mounted() {
    let colourIndex = Math.floor(Math.random() * (barChartColours.length + 1));
    let colourIndexStep = Math.floor(barChartColours.length / _.size(this._getStatuses));
    if (colourIndexStep === 0) {
      colourIndexStep = 1;
    }
    _.forEach(this._getStatuses, (status) => {
      this.statusColours[status.id] = {
        borderColor: barChartColours[colourIndex % barChartColours.length].border,
        backgroundColor: barChartColours[colourIndex % barChartColours.length].color
      };
      colourIndex = colourIndex + colourIndexStep;
    });
    this.dashboardForms = this.getForms;
    this.createTeamTable();
    this.createFormsTable();
    this.updateTeamTableData();
  },
  methods: {
    prettyPrint: function (value, type) {
      if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      } else if (type === 'Status') {
        return _.result(_.find(this.getStatuses, (status) => {
          return status.id === value;
        }), 'name') || '';
      }
    },
    isCentralBackOfficeStatus(statusId) {
      return !_.includes([
        "1fdca13d-adf6-411a-a002-a33e228d22df",
        "7795614b-720c-4581-9309-4f569895ac8d",
        "5e1159c3-e54a-4c66-ae6f-0676522b4cb9",
        "60bf9d14-ea35-44a7-88eb-30ff2eaa1712",
        "411ab033-6cba-44ce-b603-67c3080b9e2f",
        "a9525cb3-d038-4eae-8236-27e2e056597b"
      ], statusId);
    },
    createTeamTable: function () {
      this.teamTable = $('#teamStatisticsList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.teamTableData,
        rowId: 'name',
        columns: [
          {
            name: 'owner',
            data: 'name'
          },
          {
            name: 'total',
            data: 'total',
            defaultContent: ""
          },
          ..._.map(this._getStatuses, (status) => {
            return {
              name: status.id,
              data: status.id,
              defaultContent: ""
            }
          })
        ],
        columnDefs: [
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: [0]
          }
        ],
        buttons: [
          {
            extend: 'pageLength',
            text: '<i class="fas fa-filter"></i>',
            titleAttr: 'Number of Rows'
          },
          {
            extend: 'excelHtml5',
            autoFilter: true,
            text: '<i class="far fa-file-excel"></i>',
            titleAttr: 'Excel'
          }
        ],
        search: {
          regex: true,
          smart: false
        },
        select: {
          style: 'os',
          items: 'cell'
        },
        order: [[0, "asc"]]
      });

      this.teamTable.buttons()
          .container()
          .appendTo('#teamStatisticsList_wrapper .col-sm-6:eq(0)');
      this.teamTable.on('select', this.teamTableCellClick);
      this.teamTable.on('deselect', this.teamTableCellClick);
    },
    async teamTableCellClick() {
      $('.team-active-row').removeClass('team-active-row');
      $('.status-active-column').removeClass('status-active-column');
      this.teamTable.rows(_.map(this.teamTable.cells({selected: true})[0], 'row'))
          .nodes()
          .to$()      // Convert to a jQuery object
          .addClass('team-active-row');
      this.teamTable.columns(_.map(this.teamTable.cells({selected: true})[0], 'column'))
          .header()
          .to$()      // Convert to a jQuery object
          .not('.name, .total')
          .addClass('status-active-column');
      this.updateTeamChartData();
      this.updateHourlyChartData();
      this.updateFormsTableData();
    },
    createFormsTable: function () {
      this.formsTable = $('#dashboardFormsList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.formsTableData,
        rowId: 'id',
        columns: [
          {
            data: 'supervisor'
          },
          {
            data: 'owner'
          },
          {
            data: 'status'
          },
          {
            data: 'firstName'
          },
          {
            data: 'lastName'
          },
          {
            data: 'updatedAt'
          },
          {
            data: 'updatedAt'
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: "supervisor"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: "owner"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Status');
            },
            targets: "status"
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('DD-MM-YYYY');
              } else if (!data || data === '') {
                return '';
              } else {
                return moment(data, "YYYY-MM-DD").format('DD-MM-YYYY');
              }
            },
            targets: "updatedAt"
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('h:mm A');
              } else {
                return '';
              }
            },
            targets: "updatedAtTime"
          },
          {
            visible: false,
            targets: this.jobType === 'Supervisor' ? 'supervisor' : ''
          }
        ],
        "fnRowCallback": (nRow, aData) => {
          $(nRow).removeClass('vip-row');
          if (aData.verifiedIncomeProgram) {
            $(nRow).addClass('vip-row');
          }
        },
        buttons: [
          {
            extend: 'pageLength',
            text: '<i class="fas fa-filter"></i>',
            titleAttr: 'Number of Rows'
          },
          {
            extend: 'excelHtml5',
            autoFilter: true,
            text: '<i class="far fa-file-excel"></i>',
            titleAttr: 'Excel',
            exportOptions: {
              columns: '.includeInExport'
            }
          }
        ],
        search: {
          regex: true,
          smart: false
        },
        order: [[5, "desc"], [6, "desc"]]
      });

      this.formsTable.buttons()
          .container()
          .appendTo('#dashboardFormsList_wrapper .col-sm-6:eq(0)');

      this.setupFormModal();
    },
    setupFormModal: function () {
      $('#dashboardFormsList tbody').on('click', 'tr', (e) => {
        this.formDetails = this.formsTable.row(e.currentTarget).data();
        if (this.formDetails) {
          $("#formDetailsModal").modal('show');
        } else {
          this.formDetails = {};
        }
      });
    },
    getTableFormStats: function (forms) {
      let initTableData = [];
      _.forEach(forms, (teamForms, team) => {
        let teamTableRow = {
          'name': team,
          'total': teamForms.length
        };
        let teamData = _.groupBy(teamForms, 'status');
        let centralBackOfficeCount = 0;
        _.forEach(teamData, (forms, formStatus) => {
          if (_.includes(_.map(this._getStatuses, 'id'), formStatus)) {
            teamTableRow[formStatus] = forms.length;
          } else {
            centralBackOfficeCount += forms.length;
          }
        });
        if (centralBackOfficeCount > 0) {
          if (teamTableRow['a9525cb3-d038-4eae-8236-27e2e056597b'] && teamTableRow['a9525cb3-d038-4eae-8236-27e2e056597b'] !== '') {
            teamTableRow['a9525cb3-d038-4eae-8236-27e2e056597b'] += centralBackOfficeCount;
          } else {
            teamTableRow['a9525cb3-d038-4eae-8236-27e2e056597b'] = centralBackOfficeCount;
          }
        }
        initTableData.push(teamTableRow);
      });
      return initTableData;
    },
    async updateTeamTableData() {
      let teamData = this.jobType === 'Manager' ? _.groupBy(this._getForms, 'supervisor') : _.groupBy(this._getForms, 'owner');
      this.teamTableData = this.getTableFormStats(teamData);
      if (this.teamTable) {
        this.teamTable.clear();
        this.teamTable.rows.add(this.teamTableData);
        this.teamTable.draw();
      }
      this.updateTeamChartData();
      this.updateHourlyChartData();
      this.updateFormsTableData();
    },
    async updateFormsTableData() {
      this.formsTableData = this._getForms;
      let statuses = this.getStatuses;
      let selectedStatuses = $('.status-active-column');
      if (selectedStatuses.length > 0) {
        let includeCentralBackOfficeStatuses = _.includes(_.map(selectedStatuses, 'id'), "a9525cb3-d038-4eae-8236-27e2e056597b");
        statuses = _.filter(this.getStatuses, (status) => {
          return _.includes(_.map(selectedStatuses, 'id'), status.id)
              || (includeCentralBackOfficeStatuses && this.isCentralBackOfficeStatus(status.id));
        });
      }
      let selectedRows = this.teamTable.rows('.team-active-row').data();
      if (selectedRows.length > 0) {
        let teams = _.map(selectedRows, 'name');
        this.formsTableData = _.filter(this._getForms, (form) => {
          return _.includes(_.map(statuses, 'id'), form.status)
              && ((this.jobType === 'Manager' && _.includes(teams, form.supervisor))
                  || (this.jobType === 'Supervisor' && _.includes(teams, form.owner)));
        });
      }
      if (this.formsTable) {
        this.formsTable.clear();
        this.formsTable.rows.add(this.formsTableData);
        this.formsTable.draw();
      }
    },
    async updateTeamChartData() {
      let statusDatasets = {};
      let statuses = this._getStatuses;
      let includeCentralBackOfficeStatuses = true;
      let selectedStatuses = $('.status-active-column');
      if (selectedStatuses.length > 0) {
        statuses = _.filter(this._getStatuses, (status) => {
          return _.includes(_.map($('.status-active-column'), 'id'), status.id);
        });
        includeCentralBackOfficeStatuses = _.includes(_.map(selectedStatuses, 'id'), "a9525cb3-d038-4eae-8236-27e2e056597b")
      }
      _.forEach(statuses, (status) => {
        statusDatasets[status.id] = {
          label: status.name,
          data: [],
          borderColor: this.statusColours[status.id].borderColor,
          borderWidth: 2,
          backgroundColor: this.statusColours[status.id].backgroundColor
        };
      });
      let teamLabels = [];
      let initTeamChartData = this.teamTableData;
      let selectedRows = this.teamTable.rows('.team-active-row').data();
      if (selectedRows.length > 0) {
        let teams = _.map(selectedRows, 'name');
        let teamForms = _.groupBy(_.filter(this._getForms, (form) => {
          return (_.includes(_.map(statuses, 'id'), form.status)
                  || (includeCentralBackOfficeStatuses && this.isCentralBackOfficeStatus(form.status)))
              && ((this.jobType === 'Manager' && _.includes(teams, form.supervisor))
                  || (this.jobType === 'Supervisor' && _.includes(teams, form.owner)));
        }), 'owner');
        initTeamChartData = this.getTableFormStats(teamForms);
      }
      _.forEach(initTeamChartData, (teamStats) => {
        let team = this.prettyPrint(teamStats.name, 'User');
        teamLabels.push(team);
        _.forEach(statuses, (status) => {
          if (teamStats[status.id]) {
            statusDatasets[status.id].data.push({
              x: team,
              y: teamStats[status.id],
              stack: status.name
            });
          } else {
            statusDatasets[status.id].data.push({
              x: team,
              y: 0,
              stack: status.name
            });
          }
        });
      });
      this.teamChartData = {
        labels: teamLabels,
        datasets: _.values(statusDatasets)
      };
      this.teamChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            distribution: 'series',
            offset: true
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }]
        },
        plugins: {
          datalabels: {
            color: '#fff',
            formatter: function (value) {
              if (value.y && value.y > 0) {
                return value.y;
              } else {
                return null;
              }
            }
          }
        }
      }
    },
    async updateHourlyChartData() {
      let rangeSize = moment().diff(moment().startOf('day'), 'hours');
      if (rangeSize > 0) {
        let statusDatasets = {};
        let datasets = {};
        let statuses = this._getStatuses;
        let includeCentralBackOfficeStatuses = true;
        let selectedStatuses = $('.status-active-column');
        if (selectedStatuses.length > 0) {
          statuses = _.filter(this._getStatuses, (status) => {
            return _.includes(_.map($('.status-active-column'), 'id'), status.id);
          });
          includeCentralBackOfficeStatuses = _.includes(_.map(selectedStatuses, 'id'), "a9525cb3-d038-4eae-8236-27e2e056597b")
        }
        _.forEach(statuses, (status) => {
          statusDatasets[status.id] = {
            label: status.name,
            data: [],
            borderColor: this.statusColours[status.id].borderColor,
            borderWidth: 2,
            backgroundColor: this.statusColours[status.id].backgroundColor
          };
        });

        let initHourlyChartData = this._getForms;
        let selectedRows = this.teamTable.rows('.team-active-row').data();
        if (selectedRows.length > 0) {
          let teams = _.map(selectedRows, 'name');
          initHourlyChartData = _.filter(this._getForms, (form) => {
            return (_.includes(_.map(statuses, 'id'), form.status)
                    || (includeCentralBackOfficeStatuses && this.isCentralBackOfficeStatus(form.status)))
                && ((this.jobType === 'BranchManager' && _.includes(teams, form.supervisor))
                    || (this.jobType === 'Supervisor' && _.includes(teams, form.owner)));
          });
        }

        for (let index = 0; index < rangeSize + 1; index++) {
          let xValue = moment().startOf('day').add(index, 'hours').startOf('hour').format('h A');
          let startEpoch = moment().startOf('day').add(index, 'hours').startOf('hour').format('X');
          let endEpoch = moment().startOf('day').add(index, 'hours').endOf('hour').format('X');
          let forms = _.groupBy(_.filter(initHourlyChartData, (form) => {
            return form.createdAtEpoch >= startEpoch && form.createdAtEpoch <= endEpoch;
          }), this.jobType === 'Manager' ? 'supervisor' : 'owner');
          let dateRangeData = this.getTableFormStats(forms);
          _.forEach(dateRangeData, (teamStats) => {
            if (!datasets[teamStats.name]) {
              datasets[teamStats.name] = {}
            }
            _.forEach(statuses, (status) => {
              if (!datasets[teamStats.name][status.id]) {
                datasets[teamStats.name][status.id] = _.cloneDeep(statusDatasets[status.id]);
                datasets[teamStats.name][status.id].stack = teamStats.name;
              }
              if (teamStats[status.id]) {
                datasets[teamStats.name][status.id].data.push({
                  x: xValue,
                  y: teamStats[status.id],
                  stack: status.name
                });
              } else {
                datasets[teamStats.name][status.id].data.push({
                  x: xValue,
                  y: 0,
                  stack: status.name
                });
              }
            });
          });
        }

        let chartDatasets = _.flattenDeep(_.map(datasets, (dataset) => {
          return _.values(dataset);
        }));

        this.hourlyChartData = {
          datasets: chartDatasets
        };
        this.hourlyChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              stacked: true,
              title: 'time',
              type: 'time',
              distribution: 'linear',
              time: {
                parser: 'h A',
                stepSize: 1,
                unit: 'hour'
              },
              offset: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true
              }
            }]
          },
          plugins: {
            datalabels: {
              color: '#fff',
              formatter: function (value) {
                if (value.y && value.y > 0) {
                  return value.y;
                } else {
                  return null;
                }
              }
            }
          }
        };
        this.enableHourlyChart = chartDatasets.length > 0;
      } else {
        this.enableHourlyChart = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
